import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image } from 'components/Image'
import { pxToRem } from 'utils/styled'

export const SAvatarWrapper = styled.div<{ size?: number }>`
  aspect-ratio: 1;
  ${({ size }) => css`
    width: ${pxToRem(size || 60)}rem;
    height: ${pxToRem(size || 60)}rem;
  `}
  & > * {
    border-radius: 50%;
    ${({ size }) => css`
      width: ${pxToRem(size || 60)}rem;
      height: ${pxToRem(size || 60)}rem;
    `}
  }
`

export const SAvatarPlaceholder = styled.div<{ placeholderFontSize?: string }>`
  display: flex;
  border: 1px solid var(--c-blue100);
  background-color: var(--c-gray300);
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  text-transform: uppercase;
  font-size: ${(props) => props.placeholderFontSize ?? `${pxToRem(17)}rem`};
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  font-weight: 700;
  font-family: var(--f-text);
  color: var(--c-gray500);
`

export const SAvatar = styled(Image)`
  object-fit: cover;
`
