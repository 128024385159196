import { ApiType } from 'utils/types'
import { apiAuthFetcher } from 'api/goodtrust/api'
import useSWR from 'swr'
import { FetchResponse } from 'utils/fetcher'
import { useMemo } from 'react'

export const contactNotificationFilters: ApiType['UserNotificationSpecification'] = {
  types: [
    'ACCEPTED_TRUSTED_CONTACT',
    'DEMOTED_TO_TRUSTED_CONTACT',
    'PROMOTED_TO_LEGACY_CONTACT',
    'REMOVED_AS_TRUSTED_CONTACT',
  ],
  read: false,
}

export function useNotifications(filters?: ApiType['UserNotificationSpecification']) {
  const memoizedBody = useMemo(() => {
    return {
      method: 'POST',
      body: filters,
    }
  }, [filters])

  return useSWR<FetchResponse<ApiType['UserNotificationResponse'][]>>(
    ['/api/v1/notification/me', memoizedBody],
    apiAuthFetcher
  )
}

export async function postNotificationsRead(uuids: string[]) {
  return apiAuthFetcher<void>('/api/v1/notification/me/read', {
    method: 'POST',
    body: {
      uuids,
    },
  })
}
