import { useUserMe } from 'api/goodtrust/user'
import { Button } from 'components/button/Button'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { mutateAnalyticsData } from 'utils/analytics'
import { setPlanCart } from 'utils/cart'

export const UpgradeButton: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const userMeSwr = useUserMe()
  const userMe = userMeSwr.data?.json

  const { pathname } = useRouter()

  if (!userMe || !describeMyPlans(userMe).canUpgradeTo('ESTATE_PLANNING')) return null

  const onClick = () => {
    setPlanCart({
      type: 'ESTATE_PLANNING',
      firstSelectedType: 'ESTATE_PLANNING',
    })
    mutateAnalyticsData({ flow: 'upgrade_cta' })
  }

  return (
    <Button
      type="button"
      renderAsButton
      href={`/signup/checkout?next=${pathname}`}
      size="large"
      variant="secondary"
      onClick={onClick}
    >
      {t('common.upgrade_button')}
    </Button>
  )
}
