import { css } from '@emotion/react'
import { MyAvatar } from 'components/avatar/Avatar'
import { Button } from 'components/button/Button'
import { HeaderHome } from 'components/header/Header'
import { Icon } from 'components/icon'
import { PreventableLink } from 'components/preventableLink/PreventableLink'
import { Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { colors } from 'utils/colors'
import { BREAKPOINTS } from 'utils/styled'

export enum INavigationState {
  MENU,
  PROFILE,
}

export function HeaderTopNavigation(props: {
  isOpen?: boolean
  toggleOpen?: () => void
  showMenu?: boolean
  showProfile?: boolean
  logo?: ReactNode
  className?: string
  beforeExit?: () => Promise<boolean>
}) {
  const { t } = useTranslation()
  return (
    <HeaderHome className={props.className}>
      <Stack
        orientation="horizontal"
        gap="0.5rem"
        css={{ alignItems: 'center', flex: 1, justifyContent: 'space-between', gap: '1rem' }}
      >
        {props.logo && (
          <div
            css={css`
              flex: 1;
            `}
          >
            {props.logo}
          </div>
        )}
        {props.showMenu && (
          <Button
            type="button"
            variant="text"
            className="mobile-only"
            onClick={() => props.toggleOpen?.()}
            css={css`
              > span {
                display: flex;
                gap: 0.5rem;
                align-items: center;
              }

              justify-content: start;

              &:focus,
              &:hover,
              &:active {
                text-decoration: none !important;
              }
            `}
          >
            {props.isOpen ? (
              <Icon name={'Close'} size="24px" color={colors.gray[500]} />
            ) : (
              <>
                <Icon name={'MenuOpen'} size="24px" color={colors.gray[500]} />
                <Text variant={'bodyMediumBold'}>{t('common.menu')}</Text>
              </>
            )}
          </Button>
        )}
        {props.showProfile && (
          <PreventableLink
            href="/me/profile"
            css={{
              textDecoration: 'none',
            }}
            beforeHref={props.beforeExit}
          >
            <MyAvatar
              size={38}
              css={css`
                display: none;
                @media ${BREAKPOINTS.MD.max} {
                  display: flex;
                }
              `}
            />
          </PreventableLink>
        )}
      </Stack>
    </HeaderHome>
  )
}
