import Router from 'next/router'
import React, { AnchorHTMLAttributes, forwardRef } from 'react'

export const PreventableLink = forwardRef<
  HTMLAnchorElement,
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
    href: string
    beforeHref?: () => Promise<boolean>
  }
>(({ beforeHref, ...props }, ref) => {
  return (
    <a
      {...props}
      ref={ref}
      onClick={onPreventableLinkClick(props.href, beforeHref, props.onClick)}
    />
  )
})
PreventableLink.displayName = 'PreventableLink'

export const onPreventableLinkClick =
  (href: string, beforeHref?: () => Promise<boolean>, onClick?: React.MouseEventHandler) =>
  async (ev: React.MouseEvent) => {
    ev.preventDefault()
    onClick?.(ev)
    const shouldContinue = (await beforeHref?.()) ?? true
    if (!shouldContinue) return

    Router.push(href)
  }
