import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'components/button/Button'
import { HeaderNavItem } from 'components/header/Header'
import { Stack } from 'components/Stack'
import { colors } from 'utils/colors'
import { BREAKPOINTS } from 'utils/styled'

export const SHeaderNavItem = styled(HeaderNavItem)<{ active?: boolean }>`
  @media ${BREAKPOINTS.MD.max} {
    font-family: var(--f-text);
    font-size: 1rem !important;
    line-height: 1.75 !important;

    ${({ active }) =>
      active &&
      css`
        & > span:last-child {
          color: ${colors.gray[100]};
        }
        font-weight: 700;
      `}
  }
`

export const SStack = styled(Stack)`
  align-items: center;
`

export const SAvatarButton = styled(Button)`
  & > div:first-of-type {
    margin-right: 1.75rem;
    margin-left: 1.25rem;
  }
`

export const SDivider = styled.div`
  height: 1rem;
  width: 1px;
  background-color: #e7e8e9;
`

export const SAolLogoWrapper = styled.div`
  svg {
    height: 1.125rem;
  }
`

export const SLogoWrapper = styled.a`
  svg {
    width: 9.2rem;
  }
`
