import styled from '@emotion/styled'
import { IconWrapper } from 'components/iconWrapper/IconWrapper'
import { colors } from 'utils/colors'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const SNotificationTag = styled.span`
  display: inline-block;
  font-family: var(--f-text);
  font-style: normal;
  background-color: ${colors.gray[500]};
  color: white;
  border-radius: 1rem;
  padding: 0 ${pxToRem(6)}rem;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.7rem;
`

export const SDivider = styled.hr`
  margin: 0;
  height: 1px;
  width: calc(100% + 2 * var(--p-spacing));
  background: var(--c-blue100);
  border: none;

  margin-left: calc(var(--p-spacing) * -1);
  margin-right: calc(var(--p-spacing) * -2);

  @media ${BREAKPOINTS.MD.max} {
    margin: 0.5rem calc(var(--p-spacing) * -1);
  }
`

export const SNavigationIconWrapper = styled(IconWrapper)`
  margin-right: 1rem;

  @media ${BREAKPOINTS.MD.max} {
    svg {
      height: 1rem;
      width: 1rem;
    }
  }
`
export const SLogoDivider = styled.div`
  height: 1rem;
  width: 1px;
  background-color: #e7e8e9;
`
