import Head from 'next/head'

const defaultSuffix = 'GoodTrust'

export function PageTitle(props: { title?: string | null; hideDefaultSuffix?: boolean }) {
  let title = props.title?.trim()

  if (!props.hideDefaultSuffix) {
    title = `${title} | ${defaultSuffix}`
  }
  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} key="title" />
      <meta property="og:title" content={title} key="og:title" />
      <meta property="twitter:title" content={title} key="twitter:title" />
      <meta property="og:site_name" content={'MyGoodTrust'} key="og_site_name" />
    </Head>
  )
}
