import { SNotificationTag } from './NavigationDashboard.styled'
import React, { ReactNode } from 'react'

export function NavigationItemWithTag({
  children,
  notificationCount,
}: {
  children: ReactNode
  notificationCount?: number
}) {
  if (notificationCount && notificationCount > 0) {
    return (
      <>
        {children}
        <div css={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
          <SNotificationTag>{notificationCount}</SNotificationTag>
        </div>
      </>
    )
  } else {
    return <>{children}</>
  }
}
