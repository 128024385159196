import { useUserMe } from 'api/goodtrust/user'
import { ReactNode } from 'react'
import { formatName } from 'utils/format'
import { ApiType } from 'utils/types'
import { SAvatar, SAvatarPlaceholder, SAvatarWrapper } from './Avatar.styled'

export interface AvatarOwner {
  first_name?: string
  last_name?: string
  email?: string
  full_name?: string
  avatar?: ApiType['FileResponse']
}

export const Avatar = (props: {
  owner?: AvatarOwner
  size?: number
  customUrl?: string
  customName?: string
  className?: string
  placeholder?: ReactNode
  placeholderFontSize?: string
}) => {
  const { owner, size, className, customName, customUrl, placeholderFontSize } = props
  const initials = (
    customName ||
    owner?.full_name ||
    formatName(owner?.first_name, owner?.last_name) ||
    owner?.email ||
    'U'
  )
    .split(' ')
    .map((item) => item.charAt(0).toLocaleUpperCase())
    .join('')

  return (
    <SAvatarWrapper size={size} className={className}>
      <SAvatar
        placeholder={
          <SAvatarPlaceholder placeholderFontSize={placeholderFontSize}>
            {props.placeholder || initials}
          </SAvatarPlaceholder>
        }
        src={customUrl || owner?.avatar?.url}
      />
    </SAvatarWrapper>
  )
}

export function MyAvatar(props: {
  className?: string
  size?: number
  placeholderFontSize?: string
}) {
  const { data } = useUserMe()
  return <Avatar owner={data?.json} {...props} />
}
