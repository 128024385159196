import { FC } from 'react'
import { Image } from 'components/Image'

export const CobrandedPartnerLogo: FC<React.PropsWithChildren<{ logoUrl: string | undefined }>> = (
  props
) => {
  return (
    <Image
      src={props.logoUrl}
      css={{
        maxHeight: '2.375rem',
      }}
    />
  )
}
