import styled from '@emotion/styled'
import { Block } from 'components/Block'
import { css } from '@emotion/react'
import { BREAKPOINTS, pxToRem } from 'utils/styled'
import { colors } from 'utils/colors'

export const HeaderWrapper = styled(Block)<{ open?: boolean }>`
  background-color: white;
  @media ${BREAKPOINTS.MD.max} {
    &:before,
    &:after {
      content: none;
    }

    ${({ open }) => {
      if (open) {
        return css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;

          z-index: 10;
          display: flex;
          flex-direction: column;
        `
      }
      return null
    }}
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: var(--h-header);
  position: relative;
  z-index: 3;

  padding-right: var(--p-spacing);
  padding-left: var(--p-spacing);

  grid-column-start: left;
  grid-column-end: right;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

  @media ${BREAKPOINTS.SM.max} {
    border-bottom: 1px solid ${colors.blue[50]};
  }

  @media ${BREAKPOINTS.MD.max} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    overflow: visible;
  }
`

export const HeaderHome = styled.div`
  flex-grow: 1;
  margin-right: 1rem;

  @media ${BREAKPOINTS.MD.max} {
    flex-grow: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    margin-right: 0;

    & > a {
      flex-grow: 1;
    }
  }
`

export const HeaderNav = styled.nav<{ open?: boolean }>`
  flex-shrink: 0;
  display: flex;
  padding: 0 1rem;

  & > *:not(:last-child) {
    margin-right: 3.75rem;

    @media ${BREAKPOINTS.LG.max} {
      margin-right: 2rem;
    }

    @media ${BREAKPOINTS.MD.max} {
      margin-right: 0;
    }
  }

  @media ${BREAKPOINTS.MD.max} {
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: ${pxToRem(80)}rem;
    padding: 12vh 0 0;
    max-height: 86vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    ${({ open }) => {
      if (open) {
        return { display: 'flex', pointerEvents: 'all' }
      }

      return { display: 'none' }
    }}
  }
`

export const HeaderProfileNav = styled(HeaderNav)`
  display: none;

  @media ${BREAKPOINTS.MD.max} {
    width: 100%;
    padding: 0;
    min-height: 100vh;
    position: relative;
    & a {
      display: flex;
      align-items: center;
      position: relative;
    }

    ${({ open }) => {
      if (open) {
        return { display: 'block' }
      }

      return { display: 'none' }
    }}
  }
`

export const HeaderNavItem = styled.a<{ active?: boolean }>`
  color: var(--c-gray500);
  font-family: var(--f-text);
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.6875;

  text-decoration: none;

  @media ${BREAKPOINTS.MD.max} {
    font-family: var(--f-heading);
    font-size: ${pxToRem(24)}rem;
    line-height: 1.6;
    margin-right: 0;

    display: flex;
    align-items: center;
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      text-decoration: none;
    }
  }

  ${({ active }) => {
    if (active) {
      return css`
        color: var(--c-blue500);
        border-bottom: 2px solid var(--c-blue500);
        font-weight: bold;

        @media ${BREAKPOINTS.MD.max} {
          border-bottom: none;
        }
      `
    }

    return css`
      color: var(--c-gray500);

      &:hover {
        text-decoration: underline;
      }
    `
  }}
`
