import { Button } from 'components/button/Button'
import { useFreshdesk } from 'components/directive/utils/useFreshdesk'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

export const NeedHelpButton: FC = () => {
  const { t } = useTranslation()
  const freshdesk = useFreshdesk()
  return (
    <Button type="button" size="large" variant="secondary" onClick={() => freshdesk.open()}>
      {t('common.need_help_button')}
    </Button>
  )
}
