import { useUserMe } from 'api/goodtrust/user'
import { formatDirectivePersonName } from 'components/directive/utils/directiveUtils'

export function useFreshdesk() {
  const userMe = useUserMe().data?.json
  return {
    open: () => {
      if (typeof FreshworksWidget !== 'function') return
      FreshworksWidget('identify', 'ticketForm', {
        name: formatDirectivePersonName(userMe),
        email: userMe?.email,
      })
      FreshworksWidget('open', 'ticketForm')
    },
  }
}

declare function FreshworksWidget(method: 'open', where: 'ticketForm'): void
declare function FreshworksWidget(
  method: 'identify',
  where: 'ticketForm',
  data: { name?: string; email?: string }
): void
