import { css } from '@emotion/react'
import { usePendingTrustedContactInvitationCount } from 'api/goodtrust/contact'
import { contactNotificationFilters, useNotifications } from 'api/goodtrust/user/notification'
import { ActiveLink, isSubresouceLink } from 'components/ActiveLink'
import { CobrandedPartnerLogo } from 'components/cobranding/logo/CobrandedPartnerLogo'
import { Icon } from 'components/icon'
import { NavigationItemWithTag } from 'components/navigation/navigationDashboard/NavigationItemWithTag'
import { HStack } from 'components/Stack'
import { Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { cloneElement, Fragment, isValidElement, ReactElement, ReactNode } from 'react'
import { cobrandingLogic } from 'utils/cobranding/logic'
import { colors } from 'utils/colors'
import { BREAKPOINTS } from 'utils/styled'
import { SDivider, SLogoDivider, SNavigationIconWrapper } from './NavigationDashboard.styled'

export function NavigationDashboard(props: { component: ReactNode }) {
  const { t } = useTranslation()
  const cobrandedUser = cobrandingLogic.useCobrandedUser()

  if (!isValidElement(props.component)) {
    throw Error('Component must be a valid element')
  }

  const { data: notificationData } = useNotifications(contactNotificationFilters)
  const contactNotificationCount = notificationData?.json?.length
  const notificationCountSWR = usePendingTrustedContactInvitationCount()
  const notificationCount =
    (contactNotificationCount || 0) + (notificationCountSWR.data?.json?.count || 0)

  // to solve the safari cut at rhs problem
  const smallerSvg = css`
    svg {
      height: 53%;
      width: 53%;
    }
  `

  return (
    <Fragment>
      <div
        css={css`
          display: none;

          @media ${BREAKPOINTS.MD.max} {
            display: block;
          }
        `}
      >
        <ActiveLink passHref href="/" legacyBehavior>
          {cloneElement(
            props.component,
            {},
            <Fragment>
              {cobrandedUser?.partnerImageUrl ? (
                <HStack space="0.5rem" css={{ marginRight: '1rem' }}>
                  <Icon name="ColorGoodTrust" size={36} />
                  <SLogoDivider />
                  <CobrandedPartnerLogo logoUrl={cobrandedUser.partnerImageUrl} />
                </HStack>
              ) : (
                <Icon name="ColorGoodTrust" size={36} css={{ marginRight: '1rem' }} />
              )}
              <Text>GOODTRUST</Text>
            </Fragment>
          )}
        </ActiveLink>
      </div>

      <ActiveLink passHref isActive={isSubresouceLink} href="/me/dashboard" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Fragment>
            <SNavigationIconWrapper backgroundColor={colors.gray[500]} size={36} css={smallerSvg}>
              <Icon name="Home" size="1.5rem" />
            </SNavigationIconWrapper>
            <Text>{t('common.navigation.inside_navigation.dashboard')}</Text>
          </Fragment>
        )}
      </ActiveLink>

      <ActiveLink passHref isActive={isSubresouceLink} href="/me/estate-planning" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Fragment>
            <SNavigationIconWrapper backgroundColor={colors.orange[200]} size={36} css={smallerSvg}>
              <Icon name="Will" />
            </SNavigationIconWrapper>
            <Text>{t('common.navigation.inside_navigation.will_and_estate_plan')}</Text>
          </Fragment>
        )}
      </ActiveLink>

      <ActiveLink passHref isActive={isSubresouceLink} href="/me/digital-vault" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Fragment>
            <SNavigationIconWrapper backgroundColor={colors.blue[500]} size={36} css={smallerSvg}>
              <Icon name="DigitalVault" />
            </SNavigationIconWrapper>
            <Text>{t('common.navigation.inside_navigation.digital_vault')}</Text>
          </Fragment>
        )}
      </ActiveLink>

      <ActiveLink passHref isActive={isSubresouceLink} href="/me/life-stories" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Fragment>
            <SNavigationIconWrapper backgroundColor={colors.green[200]} size={36} css={smallerSvg}>
              <Icon name="LifeStories" />
            </SNavigationIconWrapper>
            <Text>{t('common.navigation.inside_navigation.life_stories')}</Text>
          </Fragment>
        )}
      </ActiveLink>

      <ActiveLink passHref isActive={isSubresouceLink} href="/me/digital-executor" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Fragment>
            <SNavigationIconWrapper backgroundColor={colors.black} size={36} css={smallerSvg}>
              <Icon name="Executor" />
            </SNavigationIconWrapper>
            <Text>{t('common.navigation.inside_navigation.digital_executor')}</Text>
          </Fragment>
        )}
      </ActiveLink>

      <SDivider />

      <ActiveLink passHref isActive={isSubresouceLink} href="/me/trusted-contact" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <NavigationItemWithTag notificationCount={notificationCount}>
            <Text>{t('common.navigation.inside_navigation.trusted_contacts')}</Text>
          </NavigationItemWithTag>
        )}
      </ActiveLink>
      <ActiveLink passHref isActive={isSubresouceLink} href="/me/shared" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Text>{t('common.navigation.inside_navigation.shared_with_me')}</Text>
        )}
      </ActiveLink>
      <ActiveLink passHref isActive={isSubresouceLink} href="/me/end-of-life-guide" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Text>{t('common.navigation.inside_navigation.end_of_life_guide')}</Text>
        )}
      </ActiveLink>
      <ActiveLink passHref isActive={isSubresouceLink} href="/me/articles" legacyBehavior>
        {cloneElement(
          props.component,
          {},
          <Text>{t('common.navigation.inside_navigation.articles')}</Text>
        )}
      </ActiveLink>
      <ActiveLink
        passHref
        isActive={isSubresouceLink}
        href="https://goodtrust.freshdesk.com/support/home"
        legacyBehavior
      >
        {cloneElement(
          props.component as ReactElement,
          { target: '_blank' },
          <Text>{t('common.navigation.inside_navigation.support')}</Text>
        )}
      </ActiveLink>
    </Fragment>
  )
}
