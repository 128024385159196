import { css } from '@emotion/react'
import { useUserMe } from 'api/goodtrust/user'
import { MyAvatar } from 'components/avatar/Avatar'
import { CobrandedPartnerLogo } from 'components/cobranding/logo/CobrandedPartnerLogo'
import { HeaderContainer, HeaderProfileNav, HeaderWrapper } from 'components/header/Header'
import { NeedHelpButton } from 'components/header/headerDashboard/NeedHelpButton'
import { UpgradeButton } from 'components/header/headerDashboard/UpgradeButton'
import { HeaderTopNavigation } from 'components/header/headerTopNavigation/HeaderTopNavigation'
import { useMobileNavigationModal } from 'components/navigation/MobileNavigationModal'
import { NavigationDashboard } from 'components/navigation/navigationDashboard/NavigationDashboard'
import { onPreventableLinkClick, PreventableLink } from 'components/preventableLink/PreventableLink'
import { HStack, Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { Trans, useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { cobrandingLogic } from 'utils/cobranding/logic'
import { LogoOfTheDay } from 'utils/cobranding/LogoOfTheDay'
import { formatName } from 'utils/format'
import {
  SAvatarButton,
  SDivider,
  SHeaderNavItem,
  SLogoWrapper,
  SStack,
} from './HeaderDashboard.styled'

export type HeaderButtonType = 'upgrade' | 'help' | 'none'

export function HeaderDashboard(props: {
  className?: string
  beforeExit?: () => Promise<boolean>
  showLogo?: boolean
  showMenu?: boolean
  buttonVariant?: HeaderButtonType
  customAvatar?: ReactNode
}) {
  const { t } = useTranslation()
  const { data } = useUserMe()
  const { isLogged } = useAuth()
  const user = data?.json
  const cobrandedUser = cobrandingLogic.useCobrandedUser()

  const [mobileModal, isMobileModalOpen, openMobileModal, closeMobileModal] =
    useMobileNavigationModal({
      customContent: (
        <HeaderProfileNav open>
          <Stack space={24}>
            <NavigationDashboard component={<SHeaderNavItem />} />
          </Stack>
          {cobrandedUser?.partnerDisplayName && (
            <Text
              css={css`
                position: absolute;
                bottom: 1rem;
              `}
              variant="bodySmall"
            >
              <Trans
                t={t}
                i18nKey="common.gt_experience_dashboard_navigation_cobranded"
                values={{
                  partner: cobrandedUser.partnerDisplayName,
                }}
              />
            </Text>
          )}
        </HeaderProfileNav>
      ),
    })

  const isProfileReady = isLogged && data?.json

  const name = formatName(user?.first_name, user?.last_name) || user?.email

  const buttonVariant: HeaderButtonType =
    user && describeMyPlans(user).hasAnyPaidPlan({ considerLifetimePaid: true })
      ? 'help'
      : props.buttonVariant ?? 'upgrade'

  return (
    <HeaderWrapper open={isMobileModalOpen} className={props.className}>
      {mobileModal}
      <HeaderContainer>
        <HeaderTopNavigation
          isOpen={isMobileModalOpen}
          toggleOpen={isMobileModalOpen ? closeMobileModal : openMobileModal}
          showMenu={props.showMenu ?? true}
          showProfile={!!isProfileReady}
          beforeExit={props.beforeExit}
          logo={
            props.showLogo ? (
              <PreventableLink
                href={isLogged ? '/me/dashboard' : '/'}
                beforeHref={props.beforeExit}
              >
                {cobrandedUser?.partnerImageUrl ? (
                  <HStack space="1rem">
                    <SLogoWrapper>
                      <LogoOfTheDay />
                    </SLogoWrapper>
                    <SDivider />
                    <CobrandedPartnerLogo logoUrl={cobrandedUser.partnerImageUrl} />
                  </HStack>
                ) : (
                  <LogoOfTheDay />
                )}
              </PreventableLink>
            ) : undefined
          }
        />

        <SStack className="desktop-only" orientation="horizontal" space="1.5rem">
          {buttonVariant === 'upgrade' && <UpgradeButton />}
          {buttonVariant === 'help' && <NeedHelpButton />}

          {isProfileReady &&
            (props.customAvatar ?? (
              <SAvatarButton
                type="button"
                variant="text"
                tone="dark"
                href="/me/profile"
                leftIcon={<MyAvatar />}
                onClick={onPreventableLinkClick('/me/profile', props.beforeExit)}
              >
                {name}
              </SAvatarButton>
            ))}
        </SStack>
      </HeaderContainer>
    </HeaderWrapper>
  )
}
